import { graphql } from 'gatsby'
import React, { useEffect, useState } from 'react'

import Gallery from '@browniebroke/gatsby-image-gallery'
import '@browniebroke/gatsby-image-gallery/dist/style.css'

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Slider from "react-slick"
import Img from "gatsby-image"
import img1 from "../../assets/img/sante/img-1.jpg"
import img2 from "../../assets/img/sante/img-2.jpg"
import img3 from "../../assets/img/sante/img-3.jpg"
import catalogue from "../../assets/img/salle-de-bain/catalogue.jpg"
import catalogueFile from "../../assets/pdf/catalogue-salle-de-bain.pdf"

const SanteCollectivitePage = ({ data }) => {
 
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const [slider1, setSlider1] = useState(null);
  const [slider2, setSlider2] = useState(null);

  useEffect(() => {
    setNav1(slider1);
    setNav2(slider2);
  },
  [slider1, slider2]
  );
  const settingsMain = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    fade: true,
    asNavFor: '.slider-nav'
  };
  const settingsThumbs = {
    slidesToShow: 3,
    slidesToScroll: 1,
    asNavFor: '.slider-for',
    dots: false,
    centerMode: true,
    swipeToSlide: true,
    focusOnSelect: true,
    centerPadding: '10px',
    arrows: false,
  };
  const images1 = data.g1.edges.map(({ node }) => node.childImageSharp)
  const images2 = data.g2.edges.map(({ node }) => node.childImageSharp)
  const images3 = data.g3.edges.map(({ node }) => node.childImageSharp)
  const images4 = data.g4.edges.map(({ node }) => node.childImageSharp)
  const images5 = data.g5.edges.map(({ node }) => node.childImageSharp)
  
 return(
  <Layout>
    <SEO title="santé et collectivités" />
    <div class="w-full relative">
            <div className="slider-wrapper">

              <Slider  {...settingsMain}  asNavFor={nav2} ref={slider => (setSlider1(slider))}>
              { data.sld.edges.map(({ node, index }) =>
                  <div className="slick-slide" key={index}>
                      <Img className="slick-slide-image" fluid={node.childImageSharp.full} />
                  </div>
                )}
              </Slider>
            </div>
            <div className="thumbnail-slider-wrap absolute left-0 right-0 bottom-0 mx-auto w-1/4">

                <Slider {...settingsThumbs} asNavFor={nav1} ref={slider => (setSlider2(slider))}>
                    { data.sld.edges.map(({ node, index }) =>
                      <div className="slick-slide slick-slide-thumb" key={index}>
                          <Img className="slick-slide-image rounded-full cursor-pointer" fluid={node.childImageSharp.thumb} />
                      </div>
                    )}
                </Slider>
            </div>
        </div>
   <div class="grid grid-cols-1 mt-8 gap-5 md:mx-40 md:my-5 mx-3">
     <h1 class="text-center text-2xl font-bold uppercase mt-5">SANTE & COLLECTIVITE</h1>
     <div class="bg-gradient-to-r from-white via-red-700 to-white h-0.5"></div>
     <p class="my-3">
      Dans l’univers médical, le Solid Surface présente tout son intérêt de par son côté hygiénique et sa facilité d’entretien et de nettoyage.</p>
   </div>
   <div class="grid grid-cols-1 md:grid-cols-2 mt-8 gap-8 md:mx-40 md:my-5 mx-3">
       <div>  <img src={img1} alt="1" /> </div>
       <div>  <img src={img2} alt="2" /> </div>
   </div>
   <div class="grid grid-cols-1 md:grid-cols-2 mt-8 gap-8 md:mx-40 md:my-5 mx-3">
   <div>
       <p class="mb-5">Corsaf réalise sur-mesure pour l’univers de la santé :</p>
       <ul class="mt-10">
            <li class="flex gap-3 items-center mb-4">
                <svg xmlns="http://www.w3.org/2000/svg" class=" h-6" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                </svg>
                <p>Plan de travail</p>
            </li>
            <li class="flex gap-3 items-center mb-4">
              <svg xmlns="http://www.w3.org/2000/svg" class=" h-6" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                </svg>
                <p>Paillasses humides est stable</p>
            </li>
            <li class="flex gap-3 items-center mb-4">
              <svg xmlns="http://www.w3.org/2000/svg" class=" h-6" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                </svg>
                <p>Lavabos</p>
            </li>
            <li class="flex gap-3 items-center mb-4">
              <svg xmlns="http://www.w3.org/2000/svg" class=" h-6" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                </svg>
                <p>Table de laboratoire</p>
            </li>
            <li class="flex gap-3 items-center mb-4">
              <svg xmlns="http://www.w3.org/2000/svg" class=" h-6" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                </svg>
                <p>Vasques, Baignoire BB, etc</p>
            </li>
        </ul>
   </div>
   <div><img src={img3} alt="3" /></div>
   </div>
   <div class="grid grid-cols-1 mt-8 gap-8 md:mx-40 md:my-5 mx-3">
       <p class="text-2xl mb-0">Bloc opératoire</p>
       <div class="hrr w-1/2"></div>
   </div>
   <div class="grid grid-cols-1 mt-8 gap-8 md:mx-40 md:my-5 mx-3 gl gl33"> {/** g1 */}
          <Gallery images={images1} />
   </div>
   <div class="grid grid-cols-1 mt-8 gap-8 md:mx-40 md:my-5 mx-3">
       <p class="text-2xl mb-0">Solid Surface et les infections nosocomiales</p>
       <div class="hrr w-1/2"></div>
   </div>
   <div class="grid grid-cols-1 mt-8 gap-8 md:mx-40 md:my-5 mx-3 gl gl33"> {/** g2 */}
          <Gallery images={images2} />
   </div>
   <div class="grid grid-cols-1 mt-8 gap-8 md:mx-40 md:my-5 mx-3">
       <p class="text-2xl mb-0">Solid Surface dans les cabinets privés</p>
       <div class="hrr w-1/2"></div>
   </div>
   <div class="grid grid-cols-1 mt-8 gap-8 md:mx-40 md:my-5 mx-3 gl gl33">{/** g3 */}
        <Gallery images={images3} />
   </div>
   <div class="grid grid-cols-1 mt-8 gap-8 md:mx-40 md:my-5 mx-3">
       <p class="text-2xl mb-0">Solid Surface dans les laboratoires, la médecine nucléaire, le virus HIV et la radiologie</p>
       <div class="hrr w-1/2"></div>
   </div>
   <div class="grid grid-cols-1 mt-8 gap-8 md:mx-40 md:my-5 mx-3 gl gl33">{/** g4 */}
        <Gallery images={images4} />
   </div>
   <div class="grid grid-cols-1 mt-8 gap-8 md:mx-40 md:my-5 mx-3">
       <p class="text-2xl mb-0">Solid Surface dans les établissements de soins</p>
       <div class="hrr w-1/2"></div>
   </div>
   <div class="grid grid-cols-1 md:grid-cols-4 mt-8 gap-8 md:mx-40 md:my-5 mx-3 gl">
        <div class="col-1 md:col-span-3"><Gallery images={images5} /></div>{/** g5 */}
        <div>
                <a href={catalogueFile} class="relative block hover-trigger" download>
                <img src={catalogue} alt="cataloge cuisine" class="my-0" /> 
                <div class="absolute left-0 right-0 text-center bottom-5 bg-white border border-grey-100 px-4 py-2 hover-target">
                    Télécharger
                </div>
                </a>
        </div>
   </div>
   </Layout>

  
)
}
export const query = graphql`
query SanteGallery {
  g1:allFile (filter:{relativeDirectory: {eq: "img/sante/g1"}}){
    edges {
      node {
        childImageSharp {
          thumb: fluid(maxWidth: 270, maxHeight: 270) {
            ...GatsbyImageSharpFluid
          }
          full: fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
  g2:allFile (filter:{relativeDirectory: {eq: "img/sante/g2"}}){
    edges {
      node {
        childImageSharp {
          thumb: fluid(maxWidth: 270, maxHeight: 270) {
            ...GatsbyImageSharpFluid
          }
          full: fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
  g3:allFile (filter:{relativeDirectory: {eq: "img/sante/g3"}}){
    edges {
      node {
        childImageSharp {
          thumb: fluid(maxWidth: 270, maxHeight: 270) {
            ...GatsbyImageSharpFluid
          }
          full: fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
  g4:allFile (filter:{relativeDirectory: {eq: "img/sante/g4"}}){
    edges {
      node {
        childImageSharp {
          thumb: fluid(maxWidth: 270, maxHeight: 270) {
            ...GatsbyImageSharpFluid
          }
          full: fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
  g5:allFile (filter:{relativeDirectory: {eq: "img/sante/g5"}}){
    edges {
      node {
        childImageSharp {
          thumb: fluid(maxWidth: 270, maxHeight: 270) {
            ...GatsbyImageSharpFluid
          }
          full: fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
  sld:allFile (filter:{relativeDirectory: {eq: "img/sante/s"}}){
    edges {
      node {
        childImageSharp {
          thumb: fluid(maxWidth: 100) {
            ...GatsbyImageSharpFluid
          }
          full: fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
}
`
export default SanteCollectivitePage